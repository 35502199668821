.actionArea {
  position: relative;
  isolation: isolate;
  &:hover {
    &.css-18z3xcy-MuiButtonBase-root-MuiCardActionArea-root {
      &:hover {
        opacity: 0.4;
      }
    }
  }
  .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 5%;
    right: 5%;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    text-align: center;
    padding: 0;
    z-index: 2;
    h3 {
      font-size: 32px;
      line-height: 58px;
    }
  }
}
