.container {
  position: sticky;
  margin: 0 auto;
  z-index: 2;
  top: 62px;
  left: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .container {
    top: 64px;
  }
}
