.gallery {
  border-radius: 6px;
  width: 100%;
  height: 400px;
}

.price {
  font-size: 1.5rem;
  font-weight: 600;
}
