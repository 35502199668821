.cardMedia {
  height: calc(200px + 10vw);
  animation: landingHoverOff 1s;

  &:hover {
    box-shadow: 0 5px 8px rgb(0 0 0 / 0.3);
    animation: landingHover 1s;
  }
}

@keyframes landingHover {
  0% {
    box-shadow: 0 0 0 rgb(0 0 0 / 0);
  }
  100% {
    box-shadow: 0 5px 8px rgb(0 0 0 / 0.3);
  }
}

@keyframes landingHoverOff {
  0% {
    box-shadow: 0 5px 8px rgb(0 0 0 / 0.3);
  }

  100% {
    box-shadow: 0 0 0 rgb(0 0 0 / 0);
  }
}
