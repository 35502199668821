.slider {
  width: 100%;
  max-width: calc(100vw - calc(24px * 2));
}

.slick-track {
  display: inline-flex;
  width: unset !important;
}

.slick-slider {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.slick-slide {
  display: inline-table;
  width: 100%;
}

.slick-list {
  width: 100%;
}

.slick-arrow {
  width: auto;
  height: auto;
  margin: 0 5px;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
}

.slick-prev {
  transform: scale(1.5) translate(0, -50%);
  position: absolute;
  left: 0;
  z-index: 2;
}

.slick-next {
  transform: scale(1.5) translate(0, -50%);
  position: absolute;
  right: 0;
}

.slick-disabled {
  display: none !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #000;
  background-color: #fff;
  outline: none;
  box-shadow: 0 5px 8px rgb(0 0 0 / 0.3);
}

@media screen and (max-width: 375px), (max-width: 640px), (max-width: 960px) {
  .slick-slider {
    width: 100%;
  }

  .slick-list {
    margin: 0;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-slide {
    width: 100%;
  }
}
