#TA_selfserveprop155 {
  font-size: 1rem !important;
}

#TA_selfserveprop155 .widSSP {
  width: 100% !important;
}

#TA_selfserveprop155 .widSSPData {
  padding: 0 !important;
}

#TA_selfserveprop155 #CDSWIDSSP .widSSPData .widSSPH11 {
  font-size: 1rem !important;
}

#TA_selfserveprop155 #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li span,
#TA_selfserveprop155 #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li a,
#TA_selfserveprop155 #CDSWIDSSP .widSSPData .widSSPAll a {
  font-size: 1rem !important;
}

/* jogja tour reviews */
#TA_selfserveprop271 {
  font-size: 1rem !important;
}

#TA_selfserveprop271 .widSSP {
  width: 100% !important;
}

#TA_selfserveprop271 .widSSPData {
  padding: 0 !important;
}

#TA_selfserveprop271 #CDSWIDSSP .widSSPData .widSSPH11 {
  font-size: 1rem !important;
}

#TA_selfserveprop271 #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li span,
#TA_selfserveprop271 #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li a,
#TA_selfserveprop271 #CDSWIDSSP .widSSPData .widSSPAll a {
  font-size: 1rem !important;
}
