.gallery-slick .slider {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  flex-direction: row-reverse;
  gap: 10px;
}

.gallery-slick .slider .slick-list {
  margin-bottom: -12px !important;
}

.gallery-slick .slick-dots {
  position: relative;
  display: flex !important;
  flex-direction: column;
  width: 150px;
  gap: 8px;
  bottom: unset;
  height: 500px;
  overflow: auto;
}

.gallery-slick .slick-dots li {
  width: 120px;
  height: 80px;
  margin: 0;
  background-color: black;
  border-radius: 8px;
}

.gallery-slick .slick-dots li.slick-active img {
  opacity: 1;
}

@media screen and (max-width: 900px) {
  .gallery-slick .slick-dots {
    display: none !important;
  }
}
