@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  font-family: 'Poppins', sans-serif;
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
}

.blog-detail-content {
  table,
  td,
  th {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-spacing: 2px;
    border-color: gray;
    border-collapse: collapse;
  }

  .blockquote {
    position: relative;
    padding-left: 2rem;
    margin-left: 0;
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: #dee2e6;
    }

    p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto !important;
    object-fit: contain;
  }
}
