.layout {
  width: 100%;
  height: 350px;
  border-radius: 12px !important;
  background-color: transparent !important;
  .actionAreaCard {
    position: relative;
    height: 100%;
    .actionArea {
      .cardMedia {
        height: 200px;
        animation: landingHoverOff 1s;
      }
    }
    &:hover {
      // box-shadow: 0 150px 150px -10px #cccccc, inset 0 -150px 0 0 #cccccc;
      .actionArea {
        .cardMedia {
          box-shadow: 0 5px 8px rgb(0 0 0 / 0.3);
          animation: landingHover 1s;
        }
      }
    }
    @keyframes landingHover {
      0% {
        // box-shadow: 0 0 0 0 #cccccc, inset 0 0 0 0 #cccccc;
        box-shadow: 0 0 0 rgb(0 0 0 / 0);
      }
      100% {
        // box-shadow: 0 150px 150px -10px #cccccc, inset 0 -150px 0 150px #cccccc;
        box-shadow: 0 5px 8px rgb(0 0 0 / 0.3);
      }
    }
    @keyframes landingHoverOff {
      0% {
        // box-shadow: 0 150px 150px -10px #cccccc,
        //     inset 0 -150px 0 150px #cccccc;
        box-shadow: 0 5px 8px rgb(0 0 0 / 0.3);
      }
      100% {
        // box-shadow: 0 0 0 0 #cccccc, inset 0 0 0 0 #cccccc;
        box-shadow: 0 0 0 rgb(0 0 0 / 0);
      }
    }
    .actionArea {
      position: absolute;
      top: 0;
      padding: 5px;
      .cardMedia {
        border-radius: 12px !important;
      }
      .content {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        bottom: 10%;
        text-align: center;
        h3 {
          font-size: 32px;
          line-height: 58px;
        }
      }
    }
  }
}

.contentCard {
  position: absolute;
  top: 200px;
}

@media screen and (max-width: 375px), (max-width: 600px) {
  .layout {
    height: 280px;
    .actionAreaCard {
      .actionArea {
        .cardMedia {
          width: 100%;
          height: 160px;
        }
      }
    }
  }

  .contentCard {
    position: absolute;
    top: 160px;
  }
}
